import { Heading, Grid, Box } from 'theme-ui';
import ArticleList from '~/components/Article/List';
import CategoryBar from './Bar';

const CategoryPage = ({ articles, category, location, categories }) => {
  return (
    <Box
      sx={{
        '--go-back-height': '4.8rem',
        backgroundColor: 'backgroundGrey',
      }}
    >
      <CategoryBar location={location} categories={categories} />
      <Grid
        sx={{
          py: ['4.8rem', '9.6rem'],
          px: ['2.4rem', '4rem'],
          maxWidth: 'var(--max-width)',
          mx: 'auto',
        }}
      >
        <Heading
          sx={{
            mb: ['1.6rem', '2.4rem '],
            fontSize: ['2.8rem', '3.6rem'],
            textTransform: 'uppercase',
            fontWeight: 'medium',
            lineHeight: 0.9,
            letterSpacing: '-0.03em',
          }}
        >
          {category}
        </Heading>
        <ArticleList articles={articles} location={location} />
      </Grid>
    </Box>
  );
};

export default CategoryPage;
