import React from 'react';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import CategoryPage from '~/components/Blog/Category';

const CategoryPageTemplate = React.forwardRef(({ data, pageContext, location }, ref) => {
  const articles = data?.allContentfulArticle?.nodes;
  const { category, categories } = pageContext;

  return (
    <Layout>
      <Metadata title={category} />
      <CategoryPage articles={articles} category={category} categories={categories} location={location} />
    </Layout>
  );
});

export const query = graphql`
  query CategoryPage($category: [String]!) {
    allContentfulArticle(
      filter: { article_gallery: { elemMatch: { slug: { eq: "blog" } } }, categories: { in: $category } }
    ) {
      nodes {
        ...ArticleCardFragment
      }
    }
  }
`;

export default CategoryPageTemplate;
